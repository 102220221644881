<template>
  <div class="au-cont">
    <div class="au-in">
      <img class="demo" src="@/assets/image/icon-4.png" alt="">
      <div class="au-name">请输入您提交申请时预留的手机号码！</div>
      <div class="au-title">{{phone.show}}</div>

      <SecurityCode v-model="value"></SecurityCode>

      <div class="btns">
        <cube-button class="btn" @click="goNext">提交</cube-button>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityCode from '@/components/SecurityCode.vue'
export default {
  name: 'Authentication',
  components: {
    SecurityCode
  },
  data () {
    return {
      id: this.$route.params.id,
      type: this.$route.query.type ? this.$route.query.type : 'view',
      phone: {
        show: '',
        key: ''
      },
      value: '',
    }
  },
  mounted () {
    this.phone.show = this.$init.pass.phone.substring(0, 3) 
    + '****'
    + this.$init.pass.phone.substring(7, 11);
    this.phone.key = this.$init.pass.phone.substring(3, 7);
  },
  methods: {
    /**
     * 验证号码
     */
    goNext () {
      if(! this.value){
        this.$createToast({
          txt: '请输入预留手机号码中间4位',
          type: 'warn'
        }).show()
        return
      }else if(this.value != this.phone.key){
        this.$createToast({
          txt: '验证失败，请核对手机号码',
          type: 'warn'
        }).show()
        return
      }
      this.$router.replace({
        path: '/ask/form',
        query: this.$route.query
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.au-cont{
  min-height: 100vh;
  display: flex;
  align-items:center;
	background: #fff;
}
.au-in{
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 80px;
}
.demo{
  display: block;
  width: 284px;
  height: 274px;
  margin: 0 auto;
}
.au-name{
  font-size: 36px;
  color: #1F955B;
  line-height: 36px;
  margin: 30px 0 80px 0;
}
.au-title{
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  margin-bottom: 45px;
  text-align: center;
}
.btns{
  width: 100%;
  padding: 80px 0 0 0;
  .btn{
    width: 540px;
    height: 90px;
    padding: 0;
    background: #1F955B;
    border-radius: 50px;
    margin: 0 auto;
  }
}

</style>